<template>
  <div>
    <div class="nav-title"><div v-for="(item ,index) in transactionManagement" :key="index" @click="tabBtn(item.router)" :class="{'active-item':route.path===item.router}"><span v-if="!item.hide" style="margin-right: 30px;"> {{ item.name }} </span></div></div>
    <el-row v-if="!megaMode" :gutter="20">
      <el-col :span="8">
        <el-card class="box-card">
          <el-radio-group v-model="tradeRadio" size="mini" @change="changeTradeRedio" style="margin-bottom:20px">
            <el-radio-button :label="0">目标股票池</el-radio-button>
            <el-radio-button :label="1">可卖持仓</el-radio-button>
          </el-radio-group>
          <spread-table :tableDetail="tableDetail" :smallType="true" @scorllChange="scorllChange" :addEvenTable="true" v-loading="loadingPlan" :tableHeight="245" :pageSize="pageSizePlan"
            :currentPage="currentPagePlan" :total="totalPlan" @sizeChange="sizeChangePlan"
            @currentChange="currentChangePlan" :tableColumn="tableListPlan[tradeRadio]" :tableData="spreadTablePlan">
          </spread-table>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-card class="box-card" v-loading="loadingCharts" v-if="!showEmpty">
          <e-charts :heightCharts="heightCharts" :option="option"></e-charts>
        </el-card>
        <el-card class="box-card" v-if="showEmpty">
          <el-empty description="暂无数据"></el-empty>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-else :gutter="20">
      <el-col :span="24">
        <el-card class="box-card" v-loading="loadingCharts" element-loading-text="查询中">
          <e-charts v-if="megaChartList.length" :heightCharts="heightCharts" :option="option"></e-charts>
          <el-empty v-else description="暂无数据"></el-empty>
        </el-card>
      </el-col>
    </el-row>
    <div style="margin: 20px 0 10px;" >
      <nav-son-menu :ref="dom" :menuData="sonMenu[sonMenuType]" @menuChange="menuChange" style="background: #fff;" :borderClass="true"></nav-son-menu>
    </div>
    <el-row style="margin-bottom: 20px;" v-if="showOther === 1" :gutter="20" class="business-box">
      <el-col :span="8">
        <div style="background:#F4F4FA;padding: 0px 20px 5px" >
          <trade-details :codeList="codeList" :price="price" @clickPrice="clickPrice" :tradeRadio="tradeRadio"
            @codeChange="codeChange" :type="sonMenuType" :megaMode="megaMode" :loadingCharts="loadingCharts"
            @getChart="getChart" :codeType="codeType + ''" @changeTitle="changeTitle"></trade-details>
        </div>
      </el-col>
      <el-col :span="16">
        <trade-card :code="code" @codeName="codeNameChange" @clickPrice="clickPrice" :codeType="codeType"></trade-card>
      </el-col>
    </el-row>
    <div v-else style="margin-bottom: 20px;" class="business-box">
      <div class="block" v-if="[1,2,3,7].indexOf(showOther) === -1" style="margin-bottom:5px">
        <span class="demonstration">
          <el-tooltip class="item" effect="dark" content="选择日期查询历史(默认查询当日)" placement="top">
            <i style="color: #3B6EFB" class="el-icon-warning"></i>
          </el-tooltip>
        </span>
        <el-date-picker size="mini" v-model="datePicker" type="daterange" @change="DateChange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-dropdown v-if="[1, 2, 8, 7, 9].indexOf(showOther) === -1" size="mini" style="margin-left: 10px" split-button
          type="primary">
          <span @click="exportExcel(showOther)">导出历史</span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="exportExcel(showOther, 1)">导出全部历史</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <el-button style="margin-left: 10px" v-if="!megaMode && showOther === 5" type="primary" size="mini"
          @click="exportExcel(showOther)">
          导出历史
        </el-button> -->
      </div>
      <div v-if="showOther === 2">
        <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage"
          :total="total" @clickButton="clickButton" @sizeChange="sizeChange" @currentChange="currentChange"
          :operation="operation" :tableColumn="tableListAll[showOther]" :tableData="spreadTable"></spread-table>
      </div>
      <div v-if="showOther === 7">
        <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage"
          :total="total" @sizeChange="sizeChange" @currentChange="currentChange"
          :tableColumn="transactionAmount[codeType]" :tableData="spreadTable"></spread-table>
      </div>
      <div class="block" v-if="[1, 2].indexOf(showOther) === -1">
        <div v-if="showOther !== 7">
          <spread-table v-loading="loading" :tableHeight="tableHeight" :pageSize="pageSize" :currentPage="currentPage"
            :total="total" @sizeChange="sizeChange" @currentChange="currentChange" :tableColumn="tableListAll[showOther]"
            :tableData="spreadTable"></spread-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, getCurrentInstance, onMounted, watch } from "vue";
import NavSonMenu from '@/components/NavSonMenu'
import SpreadTable from '@/components/SpreadTable'
import TradeCard from '@/components/TradeDealCard/courseTrade'
// import {teadeData} from "./teadeData";
import {
  menuChanges, clickGetPrice, getCharts, currentChanges, sizeChanges, DateChanges, currentChangePlans, sizeChangePlans,
  getPlanLists, codeChanges, clickButtons, allMedthods, changeTradeRedios, scorllChanges,
} from "./tradeMethods";
import { exportSettlementInfo, getSize } from "./components/TradeDetails/detailsApi";
import TradeDetails from './components/TradeDetails/index'
import ECharts from '@/components/Echarts'
// import {currentChangePlans, sizeChangePlans} from "../stock-manage/stockMethods";
import { useRoute,useRouter } from "vue-router";
import { getCodes } from "../../../components/TradeDealCard/dealMethods";
import { ElMessage } from 'element-plus';
import dayjs from "dayjs";

export default defineComponent({
  name: "index",
  components: {
    NavSonMenu,
    SpreadTable,
    TradeCard,
    TradeDetails,
    ECharts,
  },
  setup() {
    const route = useRoute();
    const router= useRouter();
    let data = reactive({
      vueExample: {},
      codeType: 1,
      tradeRadio: 0,
      tableDetail: {
        isTotal: true,
        loadingMore: false,
        noMore: false
      },

      tradeList: [
        {
          name: '买入',
          type: 'tradeMenu',
          key: 1,
        },
        {
          name: '卖出',
          type: 'tradeMenu',
          key: 2,
        },
      ],
      sonMenu: {
        '1': [
          {
            name: '交易',
            type: 'sonMenu',
            key: 1,
          },
          {
            name: '撤单',
            type: 'sonMenu',
            key: 2,
          },
          {
            name: '资金股份',
            type: 'sonMenu',
            key: 3,
          },
          {
            name: '委托详情',
            type: 'sonMenu',
            key: 4,
          },
          {
            name: '成交详情',
            type: 'sonMenu',
            key: 5,
          },
          {
            name: '资金流水',
            type: 'sonMenu',
            key: 6,
          },
          {
            name: '资金账户',
            type: 'sonMenu',
            key: 7,
          },
          {
            name: '分红送股',
            type: 'sonMenu',
            key: 8,
          },
        ],
        '2': [
          {
            name: '交易',
            type: 'sonMenu',
            key: 1,
          },
          {
            name: '撤单',
            type: 'sonMenu',
            key: 2,
          },
          {
            name: '资金股份',
            type: 'sonMenu',
            key: 3,
          },
          {
            name: '委托详情',
            type: 'sonMenu',
            key: 4,
          },
          {
            name: '成交详情',
            type: 'sonMenu',
            key: 5,
          },
          {
            name: '资金流水',
            type: 'sonMenu',
            key: 6,
          },
          {
            name: '资金账户',
            type: 'sonMenu',
            key: 7,
          },
        ],
        '3': [
          {
            name: '交易',
            type: 'sonMenu',
            key: 1,
          },
          {
            name: '撤单',
            type: 'sonMenu',
            key: 2,
          },
          {
            name: '资金股份',
            type: 'sonMenu',
            key: 3,
          },
          {
            name: '委托详情',
            type: 'sonMenu',
            key: 4,
          },
          {
            name: '成交详情',
            type: 'sonMenu',
            key: 5,
          },
          {
            name: '资金流水',
            type: 'sonMenu',
            key: 6,
          },
          {
            name: '资金账户',
            type: 'sonMenu',
            key: 7,
          },
          {
            name: '保证金',
            type: 'sonMenu',
            key: 9,
          },
        ]
      },
      sonMenuType: 1,
      tableList: [
        { name: '代码', text: 'date' },
        { name: '名称', text: 'name' },
        { name: '数量', text: 'address' },
      ],
      code: '',
      codeName: '',
      price: '',
      direction: false,
      showOther: 1,

      heightCharts: 305,
      option: {},
      // tag类型  股票 基金 ...
      tagType: 1,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],

      startTime: undefined,
      endTime: undefined,
      datePicker: '',
      // 期货10 其他0
      exchange: 0,
      routeId: 1,
      tableListAll: {
        '2': [
          { name: '交易单号', text: 'omid' },
          { name: '交易所', text: 'exType' },
          { name: '证券代码', text: 'code' },
          { name: '证券名称', text: 'fname' },
          { name: '买卖方向', text: 'direction' },
          { name: '委托状态', text: 'orderStatusName' },
          { name: '委托价格', text: 'orderPrice' },
          { name: '委托数量', text: 'orderVol' },
          { name: '平均成交价', text: 'averagePrice' },
          { name: '成交数量', text: 'tradedVol' },
          { name: '下单时间', text: 'addDateTime' },
          { name: '备注', text: 'errorMsg' },
        ],
        '3': [
          { name: '证券代码', text: 'marketSID' },
          { name: '证券名称', text: 'name' },
          { name: '持仓数量', text: 'amount' },
          { name: '可用数量', text: 'availableAmount' },
          { name: '冻结数量', text: 'frozenAmount' },
          { name: '成本价', text: 'averageOpenPrice' },
          { name: '市价', text: 'currentPrice' },
          { name: '市值', text: 'marketValue' },
          { name: '浮动盈亏', text: 'floatingPL' },
          { name: '交易市场', text: 'marketPlace' },
        ],
        '4': [
          { name: '交易单号', text: 'omid' },
          { name: '交易所', text: 'exType' },
          { name: '证券代码', text: 'code' },
          { name: '证券名称', text: 'fname' },
          { name: '买卖方向', text: 'direction' },
          { name: '订单状态', text: 'orderStatusName' },
          { name: '平均价格', text: 'averagePrice' },
          { name: '下单价格', text: 'orderPrice' },
          { name: '下单数量', text: 'orderVol' },
          { name: '成交数量', text: 'tradedVol' },
          { name: '下单时间', text: 'addDateTime' },
          { name: '完成时间', text: 'updateDateTime' },
        ],
        '5': [
          { name: '合同编号', text: 'id' },
          { name: '交易单号', text: 'oid' },
          { name: '证券类型', text: 'exType' },
          { name: '证券代码', text: 'fgsid' },
          { name: '证券名称', text: 'fname' },
          { name: '买卖方向', text: 'direction', hidden: !(route.params.id === '1' || route.params.id === '2') },
          { name: '开仓方向', text: 'tposition', hidden: !(route.params.id === '3') },
          { name: '下单价格', text: 'orderPrice' },
          { name: '成交价格', text: 'tradePrice' },
          { name: '下单数量', text: 'tradeVol' },
          { name: '成交时间', text: 'tradeTime' },
          // { name: '成交日期', text: 'today' },
        ],
        '6': [
          { name: '时间', text: 'time' },
          { name: '现金类型', text: 'cashflowName' },
          { name: '交易类型', text: 'currencyName' },
          { name: '现金周转', text: 'cashflow' },
          { name: '保留现金', text: 'remainedcash' },
          { name: '备注', text: 'comment' },
        ],
        '8': [
          { name: '序号', text: 'id' },
          { name: '日期', text: 'date' },
          { name: '股票代码', text: 'code' },
          { name: '股票名称', text: 'name' },
          { name: '现金分红比例', text: 'dividenRatio' },
          { name: '股票分红比例', text: 'donusShareRatio' },
          { name: '转增比例', text: 'allotmentRatio' },
          { name: '转增价格', text: 'allotmentPRice' },
          { name: '现金分红', text: 'dividen' },
          { name: '股票分红', text: 'donusShares' },
          { name: '转增份额', text: 'allotmentShares' },
          { name: '转增总额', text: 'allotmentCost' },
        ],
        '9': [
          { name: '证券代码', text: 'code' },
          { name: '交易所类型', text: 'exchange' },
          { name: '保证金', text: 'margin' },
        ],
      },
      transactionAmount: {
        '1': [
          { name: '可用余额', text: 'freeAmount' },
          { name: '总值', text: 'amounts' },
          { name: '冻结金额', text: 'frozenAmount' },
          { name: '税费佣金', text: 'taxFeeCommssion' },
        ],
        '2': [
          { name: '可用余额', text: 'freeAmount' },
          { name: '总值', text: 'amounts' },
          { name: '冻结金额', text: 'frozenAmount' },
          { name: '税费佣金', text: 'taxFeeCommssion' },
        ],
        '3': [
          { name: '可用余额', text: 'freeAmounts' },
          { name: '总值', text: 'amounts' },
          { name: '冻结金额', text: 'frozenAmount' },
          { name: '税费佣金', text: 'feeTax' },
          { name: '保证金', text: 'margin' },
          { name: '风险度', text: 't_trade_riskratio' },
        ],
      },
      tableHeight: 550,

      tableListPlan: {
        '0': [
          { name: '证券代码', text: 'internalsid' },
          { name: '简称', text: 'name' },
          { name: '目标数量', text: 'targetamount' },
        ],
        '1': [
          { name: '代码', text: 'internalsid' },
          { name: '简称', text: 'name' },
          { name: '可卖量', text: 'targetamount' },
        ],
      },
      currentPagePlan: 1,
      pageSizePlan: 4,
      totalPlan: 0,
      loadingPlan: true,
      spreadTablePlan: [],
      codeList: [],


      megaMode: false,// route.name === 'trade' 为大赛
      megaChartList: [], //大赛charts
      chartTitle: '', // 大赛页面时的chart标题
      operation: ['撤单'],

      loadingCharts: false,
      showEmpty: false,
      dateRange: null,
      sizeNum: 0,
      transactionManagement:[],
    })


    const mega = JSON.parse(sessionStorage.getItem('mega')) || {}

    let refs = '';

    const dom = el => {
      refs = el;
    }
    onMounted(() => {
      if(sessionStorage.getItem('transactionManagement')){
        data.transactionManagement=JSON.parse(sessionStorage.getItem('transactionManagement'))
      }
      //  sessionStorage.getItem('transactionManagement',JSON.stringify(index.children[0]))
      console.log('SpreadTable',data.tableListAll);
      data.vueExample = getCurrentInstance()
      data.routeId = route.params.id
      data.codeType = parseInt(route.params.id)
      data.sonMenuType = parseInt(route.params.id)
      data.megaMode = route.name === '/megagame/trade/:id'
      changeTradeRedios(data)
    })

    watch(() => route.params.id, (val, prevVal) => {
      if (route.name == "login") {
        return
      } else {
        if (val === '2' || val === '1') {//股票，债券时显示买卖方向
          data.tableListAll['5'][5].hidden = false;
          data.tableListAll['5'][6].hidden = true;
        } else { //期货显示开仓方向
          data.tableListAll['5'][6].hidden = false;
          data.tableListAll['5'][5].hidden = true;
        }
        data.routeId = route.params.id
        data.codeType = parseInt(route.params.id)
        data.megaMode = route.name === '/megagame/trade/:id'
        data.sonMenuType = parseInt(route.params.id)
        changeTradeRedios(data)
        allMedthods(data)
      }

    })
    let getChart = (val) => {
      getCharts(data, val)
    }
    let getPlanList = () => {
      getPlanLists(data)
    }
    let menuChange = (value) => {
      menuChanges(value, data, refs);
    }
    let clickPrice = (value) => {
      clickGetPrice(value, data)
    }
    let sizeChange = (val) => {
      sizeChanges(val, data)
    }
    let sizeChangePlan = (val) => {
      sizeChangePlans(val, data)
    }

    let currentChange = (val) => {
      currentChanges(val, data)
    }
    let currentChangePlan = (val) => {
      currentChangePlans(val, data)
    }

    let DateChange = (val) => {
      DateChanges(val, data)
    }

    let codeChange = (val) => {
      codeChanges(val, data)
    }
    let codeNameChange = (val) => {
      data.codeName = val
    }
    let clickButton = (val) => {
      clickButtons(val, data)
    }
    let changeTradeRedio = () => {
		data.currentPagePlan = 1;
      changeTradeRedios(data)
    }
    let scorllChange = (val) => {
      scorllChanges(data, val)
    }

    const getSizeNum = () => {
      if (data.dateRange === null) {
        ElMessage.warning('请选择筛选时间')
        return
      }
      const params = {
        acid: data.megaMode ? mega.acid : JSON.parse(sessionStorage.getItem('acid')),
        startTime: dayjs(data.dateRange[0]).format('YYYYMMDD'),
        endTime: dayjs(data.dateRange[1]).format('YYYYMMDD')
      }
      let url = "/tradelab/export/exportHistoricalPositions"
      let date = dayjs(Date.parse(new Date())).format("YYYY-MM-DD")
      let FileName = '资金股份' + '-' + date

      // getSize(reqData).then(res => {
      //   console.log(res,'res');
      //   const resData = res.data
      //   console.log('resData.msg',resData.msg);
      //   if (resData.status === 0 || resData.code == 200) {
      //     console.log('resData.msg',resData.msg);
      //     data.sizeNum = parseInt(resData.msg);
      //     data.sizeNum > 0 ? exportExl() : ElMessage.warning('无可导出数据')
      //     console.log( data.sizeNum,33333);
      //   } else {
      //     console.log( data.sizeNum,33333);
      //     ElMessage.warning('无可导出数据')
      //   }
      // }).catch(err => {
      // })
    }

    const exportExl = () => {
      const reqData = {
        acid: mega.acid,
        size: data.sizeNum,
        startTime: data.dateRange[0],
        endTime: data.dateRange[1],
      }
      exportSettlementInfo(reqData).then(res => {
        const resData = res
        if (resData.status === 200) {
          let FileName = decodeURIComponent(resData.headers['content-disposition'].split('=')[1])
          const blob = new Blob([resData.data], { type: resData.headers['content-type'] });
          if (!!window.ActiveXObject || "ActiveXObject" in window) {
            navigator.msSaveBlob(blob, FileName)
          } else {
            const elink = document.createElement('a');
            elink.download = FileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
            if (--data.sizeNum > 0) {
              exportExl()
            }
          }
        }
      }).catch(err => {
      })
    }

    const changeTitle = title => {
      data.chartTitle = title
    }

    const downExcel = (url, params, FileName) => {
      const { proxy } = data.vueExample
      proxy.$DownloadAsExcel(url, params, FileName)
    }
    const tabBtn=(url)=>{
      router.push(url)
    }
    const exportExcel = (num, type) => {
      let url = ''
      let params = {}
      let FileName = ''
      if ((!data.datePicker || data.datePicker == null) && !type) {
        ElMessage.warning('请选择筛选时间')
        return
      }
      // 如果 有 type 则导出全部，否则导出一段时间
      if (num === 3) {//资金股份
        FileName = '资金股份'
        if (type) {
          // window.location.href = '/tradelab/export/exportHistoricalPositions?acid=' +
          //   JSON.parse(sessionStorage.getItem('acid'))
          url = "/tradelab/export/exportHistoricalPositions"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
          }

        } else {
          // window.location.href = '/tradelab/export/exportPeriodHistoricalPositions?acid=' +
          //   JSON.parse(sessionStorage.getItem('acid')) + '&startTime=' + dayjs(data.dateRange[0]).format('YYYYMMDD')
          //   + '&endTime=' + dayjs(data.dateRange[1]).format('YYYYMMDD')
          url = "/tradelab/export/exportPeriodHistoricalPositions"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
            startTime: dayjs(data.startTime * 1000).format('YYYYMMDD'),
            endTime: dayjs(data.endTime * 1000).format('YYYYMMDD')
          }
        }
      }
      if (num === 4) {//委托详情
        FileName = '委托详情'
        if (type) {
          // window.location.href = '/tradelab/export/exportOrderstoday?acid='
          //   + JSON.parse(sessionStorage.getItem('acid'))
          url = "/tradelab/export/exportOrderstoday"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid'))
          }
        } else {
          // window.location.href = '/tradelab/export/exportPeriodOrdershistory?acid='
          //   + JSON.parse(sessionStorage.getItem('acid')) + '&startTime=' + dayjs(data.startTime * 1000).format('YYYYMMDD')
          //   + '&endTime=' + dayjs(data.endTime * 1000).format('YYYYMMDD')
          url = "/tradelab/export/exportPeriodOrdershistory"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
            startTime: dayjs(data.startTime * 1000).format('YYYYMMDD'),
            endTime: dayjs(data.endTime * 1000).format('YYYYMMDD')
          }
        }
      }
      if (num === 5) {//成交详情}
        FileName = '成交详情'
        if (type) {
          // window.location.href = '/tradelab/export/exportHistoricalCashflows?acid='
          //   + JSON.parse(sessionStorage.getItem('acid'))
          url = "/tradelab/export/exportHistoricalCashflows"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid'))
          }

        } else {
          // window.location.href = '/tradelab/export/exportHistoricalTransactionsy?acid='
          //   + JSON.parse(sessionStorage.getItem('acid')) + '&startTime=' + dayjs(data.startTime * 1000).format('YYYYMMDD')
          //   + '&endTime=' + dayjs(data.endTime * 1000).format('YYYYMMDD')
          url = "/tradelab/export/exportHistoricalTransactionsy"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
            startTime: dayjs(data.startTime * 1000).format('YYYYMMDD'),
            endTime: dayjs(data.endTime * 1000).format('YYYYMMDD')
          }
        }
      }
      if (num === 6) {//资金流水}
        FileName = '资金流水'
        if (type) {
          // window.location.href = '/tradelab/export/exportHistoricalCashflows?acid='
          //   + JSON.parse(sessionStorage.getItem('acid'))
          url = "/tradelab/export/exportHistoricalCashflows"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid'))
          }

        } else {
          // window.location.href = '/tradelab/export/exportPeriodHistoricalCashflows?acid='
          //   + JSON.parse(sessionStorage.getItem('acid')) + '&startTime=' + dayjs(data.startTime * 1000).format('YYYYMMDD')
          //   + '&endTime=' + dayjs(data.endTime * 1000).format('YYYYMMDD')
          url = "/tradelab/export/exportPeriodHistoricalCashflows"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
            startTime: dayjs(data.startTime * 1000).format('YYYYMMDD'),
            endTime: dayjs(data.endTime * 1000).format('YYYYMMDD')
          }
        }
      }
      if (num === 7) {//资金账户
        FileName = '资金账户'
        if (type) {
          // window.location.href = '/tradelab/export/exportFundaccount?acid='
          //   + JSON.parse(sessionStorage.getItem('acid')) + '&ctype=' + data.codeType
          url = "/tradelab/export/exportFundaccount"
          params = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
            ctype: data.codeType
          }

        } else {
          // window.location.href = '/tradelab/export/exportPeriodFundaccount?acid='
          //   + JSON.parse(sessionStorage.getItem('acid')) + '&startTime=' + dayjs(data.startTime * 1000).format('YYYYMMDD')
          //   + '&endTime=' + dayjs(data.endTime * 1000).format('YYYYMMDD')
          //   + '&ctype=' + data.codeType
          url = "/tradelab/export/exportPeriodFundaccount"

          params = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
            startTime: dayjs(data.startTime * 1000).format('YYYYMMDD'),
            endTime: dayjs(data.endTime * 1000).format('YYYYMMDD'),
            ctype: data.codeType
          }
        }
      }
      let date = dayjs(Date.parse(new Date())).format("YYYY-MM-DD")
      FileName = FileName + '-' + date
      downExcel(url, params, FileName)
    }

    return {
      ...toRefs(data),
      getChart,
      downExcel,
      menuChange,
      clickPrice,
      dom,
      getPlanList,
      sizeChange,
      sizeChangePlan,
      DateChange,
      currentChange,
      currentChangePlan,
      codeChange,
      codeNameChange,
      clickButton,
      getSizeNum,
      changeTitle,
      exportExcel,
      changeTradeRedio,
      scorllChange,
      route,
      tabBtn

    }
  }
})
</script>

<style scoped lang="less">
.business-box{
  // height: calc(100vh - 750px);
  // min-height: 250px;
  // overflow: auto;
}
.box-card {
  /*height: 500px;*/
  min-height: 350px;
}

.block {
  position: relative;
  text-align: right;

  .demonstration {
    font-size: 13px;
    color: #707070;
    margin-right: 10px;
  }
}

nav {
  // position: absolute;
  // right: 0px;
  // top: -54px;
}
.nav-title{
  display: flex;
  margin-bottom:20px;
  margin-left: 20px;
  div{
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    font-family: MicrosoftYaHei;
    color: #03032C;
  }
  .active-item{
  font-size: 18px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3B6EFB;
}
}

</style>
